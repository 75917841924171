.fade-enter {
    opacity: 0;
    transform: translateX(100%); /* Начальная позиция справа */
}

.fade-enter-active {
    opacity: 1;
    transform: translateX(0); /* Конечная позиция */
    transition: opacity 300ms, transform 300ms; /* Плавный переход */
}

.fade-exit {
    opacity: 1;
    transform: translateX(0); /* Начальная позиция */
}

.fade-exit-active {
    opacity: 0;
    transform: translateX(-50%); /* Уход влево */
    transition: opacity 300ms, transform 300ms; /* Плавный переход */
}

.page {
    padding: 20px;
    font-size: 24px;
}


/* Анимация появления для флексов */
.e-flex > * {
    opacity: 0; /* Скрываем элементы */
    transform: translateY(20px); /* Сдвигаем вниз */
    animation: fadeInUp 500ms ease-out forwards; /* Анимация */
}

.e-flex > *:nth-child(1) {
    animation-delay: 0ms; /* Без задержки */
}

.e-flex > *:nth-child(2) {
    animation-delay: 200ms; /* Задержка для второго элемента */
}

.e-flex > *:nth-child(3) {
    animation-delay: 400ms; /* Задержка для третьего элемента */
}

/* Определение анимации */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Анимация появления */
.game > .row {
    opacity: 0; /* Скрываем элементы */
    transform: translateY(20px); /* Сдвигаем вниз */
    animation: fadeInUp 500ms ease-out forwards; /* Анимация появления */
}

/* Задержки для появления */
.game > .row:nth-child(1) {
    animation-delay: 0ms;
}
.game > .row:nth-child(2) {
    animation-delay: 200ms;
}
.game > .row:nth-child(3) {
    animation-delay: 400ms;
}
.game > .row:nth-child(4) {
    animation-delay: 600ms;
}
.game > .row:nth-child(5) {
    animation-delay: 800ms;
}
.game > .row:nth-child(6) {
    animation-delay: 1000ms;
}
.game > .row:nth-child(7) {
    animation-delay: 1200ms;
}
.game > .row:nth-child(8) {
    animation-delay: 1400ms;
}
.game > .row:nth-child(9) {
    animation-delay: 1600ms;
}
.game > .row:nth-child(10) {
    animation-delay: 1800ms;
}

/* Определение анимации появления */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
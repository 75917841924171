/* STYLES 08.12.2024
Developer: lapse.site
Screen resolution: 460, 860, 1440, 1920 */
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');

:root {
  --bg: #152849;
  --bg2: #3c5073;
  --bg3: #101e37;
  --text: #fff;
  --text1: #527997;
  --color1: #58a6d6;
  --color2: #63b9ee;
  --light: rgb(255 255 255 / 10%);
  --light2: rgb(255 255 255 / 30%);
  --dark: rgb(0 0 0 / 30%);
  --radius: 20px;
}

* {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.e-btn {
  background: linear-gradient(180deg, #fb9830, #fc7b41);
}

body {
  background: var(--bg);
  color: var(--text);
  line-height: normal;
  font-family: "Unbounded", sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 100vh;
  width: 100%;
}

@media (min-width: 840px) {
  body {
    font-size: 14px;
  }
  h2 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.App {
  text-align: center;
  display: flex;
  height: 100vh;
  overflow: hidden;
}

/* HEADER */
header,
footer {
  position: relative;
  margin: 20px 0;
  z-index: 10;
}

header .profile {}

header .profile img {
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
}

header .stat {
  position: relative;
  background: var(--bg2);
  padding: 0 10px;
  padding-left: 20px;
  border-radius: var(--radius);
  height: 30px;
}

header .stat img {
  position: absolute;
  left: -30%;
  margin: 20px 0;
}

button {
  cursor: pointer;
}

.button img {
  width: 60px;
  aspect-ratio: 1/1;
  filter: drop-shadow(2px 2px 6px #00000080);
}

.button img.logo {

  margin: 10px;
  width: 100px;
  aspect-ratio: auto;
  filter: drop-shadow(2px 2px 6px #00000080);
}

/* ELEMENTS */
.menu-button {
  background: url('../../public/images/game/icons/button.png') no-repeat;
  background-position: center;
  background-size: contain;
  width: max-content;
  filter: drop-shadow(2px 2px 6px #00000080);
  margin: 0 auto;
}

.menu-button span {
  font-size: 16px;
}

.MainPage>.menu-button:not(:nth-child(1)),
.ModePage>.menu-button:not(:nth-child(1)),
.menu-button-blue {
  background: url('../../public/images/game/icons/button_blue.png') no-repeat;
  background-position: center;
  background-size: contain;
  width: max-content;
}

.menu-button button,
.menu-button a {
  display: block;
  color: var(--text);
  font-size: 20px;
  text-shadow: 0 0 20px black;
  padding: 35px 55px;
}

/* Main Page */
.MainPage {}

.MainPage .menu-button:nth-child(1) {
  transform: scale(1.2);
}

/* Levels Page */
.LevelsPage,
.ModePage {
  flex-wrap: nowrap;
  overflow-y: scroll;
}

/* GAME */
.GamePage {
  position: relative;
  flex-wrap: nowrap;
  left: 0;
  transition: 0.7s all ease-in-out;
  z-index: 1;
}

@media (min-width: 860px) {
  .GamePage {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: 50px;
  }
}

.GamePage.fade-out {
  left: -120%;
}

.GamePage.blur {
  filter: blur(3px) brightness(0.8);
}

.GamePage .timer {
  margin-top: 20px;
}

@media (min-width: 840px) {
  .GamePage .timer {
    margin-top: 5px;
  }
}

.game {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  user-select: none;
  border-radius: var(--radius);
  overflow: hidden;
  transition: all;
}

.row.hide {
  opacity: 0;
  transform: scale(0.5);
}

@media (min-width: 860px) {

  .game {
    width: 30%;
    height: auto;
  }

  .row {
    width: 100%;
    height: auto;
  }
}

.row {
  display: flex;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.cell {
  position: relative;
  width: 100%;
  /* Ширина ячейки */
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  /* Центрирование содержимого */
  align-items: center;
  /* Центрирование содержимого */
  cursor: pointer;
  /* Курсор в виде указателя при наведении */
  transition: transform 0.2s;
  /* Плавный переход при повороте */
  background-color: var(--bg2);
}

.pipe-image {
  width: 100%;
  /* Чтобы изображение занимало всю ячейку */
  height: auto;
  /* Автоматическая высота для сохранения пропорций */
  transition: 0.3s all;
}

.pipe-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* WIN PAGE */
.WinPage {
  opacity: 0;
  transform: scale(0.1);
  position: absolute;
  height: 100%;
  width: 100%;
  transition: .4s all ease-in-out;
  z-index: 0;
}

.WinPage.fade-in {
  opacity: 1;
  transform: scale(1);
  z-index: 9;
}

.WinPage .card {
  background-color: var(--bg);
  width: 300px;
  min-height: 200px;
  margin: auto;
  padding: 20px;
  border-radius: var(--radius);
  border: 2px solid var(--bg2);
  box-shadow: 0 0 30px 4px #000000a8, inset 0 0 10px #000000a8;
}

.WinPage h2 {
  background: linear-gradient(165deg, #ffd25e 16.44%, #b59646 63.42%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  text-transform: uppercase;
}

/* STATS */
.StatsPage {
  height: 100%;
  flex-wrap: nowrap;
}

.StatsPage .stats {
  height: 80%;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 20px;
}

.StatsPage table {
  background: var(--bg3);
  margin: auto;
  padding: 20px;
  border-collapse: collapse;
  font-size: 14px;
}

@media (min-width: 860px) {
  .StatsPage table {
    width: 100%;
    font-size: 16px;
  }
}

.StatsPage table tr {
}

.StatsPage table thead > td {
  border-bottom: 2px solid #ffffff6e;
}

.StatsPage table td {
  padding: 10px;
}

.StatsPage table tr:not(:last-child) > td {
  padding: 5px;
  border-bottom: 1px solid var(--bg2);
}

.StatsPage table tr > td:nth-child(2) {
  text-align: left;
}

/* LearnPage */
.LearnPage {

}

.LearnPage br {
  display: none;
}


.LearnPage img {
  width: 80%;
}

.LearnPage > p {
  line-height: 1.2;
  font-size: 12px;
}

@media (min-width: 840px) {
  .LearnPage .e-cover img {
    width: 30%;
  }
  .LearnPage > p {
    line-height: 1.6;
    font-size: 14px;
  }
  .LearnPage br {
    display: block;
  }
}